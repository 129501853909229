import React from "react";

import { socialData } from "../data";

const Socials = () => {
  return (
    <ul className="flex items-center justify-center gap-x-[30px]">
      {socialData.map((item, index) => {
        return (
          <li key={index}>
            <a href={item.href} target="_blank" rel="noopener noreferrer">
              {item.icon}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default Socials;
