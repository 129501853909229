import React from "react";

import abtImg from "../img/about/Cuba.jpg";

import { aboutData } from "../data";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const About = () => {
  const { title, subtitle1, subtitle2, btnText, btnIcon } = aboutData;

  return (
    <section id="about" className="lg:py-16 xl:pb-[160px]">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-x-[70px] items-center">
          <motion.div
            variants={fadeIn("left")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="flex flex-col mt-10 lg:flex-row flex-1"
          >
            <img className="w-full" src={abtImg} alt="" />
            {/*<div className="text-[160px] xl:text-[520px] leading-none font-tertiary lg:-tracking-[0.055em] bg-about bg-no-repeat bg-center bg-clip-text text-transparent">
              Me
            </div>*/}
          </motion.div>
          <motion.div
            variants={fadeIn("right")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.6 }}
            className="flex-1 h-full my-10 xl:mt-48"
          >
            <h2 className="h2">{title}</h2>
            <div className="flex flex-col items-end">
              <div className="max-w-[530px] text-grey">
                <p className="mb-6">{subtitle1}</p>
                <p className="mb-9">{subtitle2}</p>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
