import React, { useState } from "react";

import { graphicData } from "../data";

import RowsPhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

function assetLink(asset, width) {
  return `https://assets.react-photo-album.com/_next/image?url=${encodeURIComponent(
    `/_next/static/media/${asset}`
  )}&w=${width}&q=75`;
}

const slides = graphicData.images.map(({ original, width, height }) => ({
  src: assetLink(original, width),
  width,
  height,
  srcSet: breakpoints.map((breakpoint) => ({
    src: assetLink(original, breakpoint),
    width: breakpoint,
    height: Math.round((height / width) * breakpoint),
  })),
}));

const GraphicDesign = () => {
  const [index, setIndex] = useState(-1);
  const { title, btnText, btnIcon, images } = graphicData;

  return (
    <section id="graphic-design" className="section relative mt-[40px] lg:mt-0">
      <div className="container mx-auto">
        <motion.h2
          variants={fadeIn("up")}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.6 }}
          className="h2 max-w-[370px] lg:mb-20"
        >
          {title}
        </motion.h2>
      </div>
      <motion.div
        variants={fadeIn("up")}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.2 }}
        className="max-w-[1300px] px-10 mx-auto mb-8 lg:mb-20"
      >
        <RowsPhotoAlbum
          onClick={({ index }) => setIndex(index)}
          layout="rows"
          columns={4}
          photos={images}
        />
        <Lightbox
          slides={images}
          styles={{ container: { backgroundColor: "rgba(0,0,0,0.9)" } }}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
        />
      </motion.div>
      <motion.div
        variants={fadeIn("up")}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.2 }}
        className="flex justify-center "
      ></motion.div>
    </section>
  );
};
export default GraphicDesign;
