import React from "react";

// import components
import Hero from "./components/Hero";
import Header from "./components/Header";
import About from "./components/About";
import GallerySection from "./components/GallerySection";
import Footer from "./components/Footer";
import GraphicDesign from "./components/GraphicDesign";
import Comapnies from "./components/Comapnies";
import CreativeDirection from "./components/CreativeDirection";

const App = () => {
  return (
    <div className="max-w-[1920px] mx-auto overflow-hidden bg-white">
      <Header />
      <Hero />
      <About />
      <Comapnies />
      <GallerySection />
      <GraphicDesign />
      <CreativeDirection />
      <Footer />
    </div>
  );
};

export default App;
