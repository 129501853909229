import React from "react";

import { companiesData } from "../data";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Navigation } from "swiper";

import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const Comapnies = () => {
  return (
    <motion.section
      variants={fadeIn("up")}
      initial="hidden"
      whileInView={"show"}
      viewport={{ once: false, amount: 0.1 }}
      className="py-[40px] lg:pb-[160px] lg:pt-0"
    >
      <div className="container mx-auto">
        <Swiper
          centeredSlides={true}
          spaceBetween={50}
          slidesPerView={6}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Navigation]}
        >
          {companiesData.images.map((slide, index) => {
            const { imgSrc, imgAlt } = slide;
            return (
              <SwiperSlide key={index}>
                <div className="">
                  <div className="lg:w-[8rem] h-[8rem] mb-5 flex justify-center items-center">
                    <img src={imgSrc} alt={imgAlt} />
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </motion.section>
  );
};
export default Comapnies;
