// import images
//import Logo from '../src/img/header/logo.svg';
import RadziLogo from "../src/img/header/Radzi_Logo_Black.png";
import AboutImg from "../src/img/about/Cuba.jpg";
import GalleryImg1 from "../src/img/gallery/1.png";
import GalleryImg2 from "../src/img/gallery/2.png";
import GalleryImg3 from "../src/img/gallery/3.png";
import GalleryImg4 from "../src/img/gallery/4.png";
import GalleryImg5 from "../src/img/gallery/5.png";
import GalleryImg6 from "../src/img/gallery/6.png";
import GalleryImg7 from "../src/img/gallery/7.png";
import GalleryImg8 from "../src/img/gallery/8.png";
import QuoteImg from "../src/img/testimonial/quote.svg";
//photography images
import image1 from "../src/img/photography/image1.JPG";
import image2 from "../src/img/photography/image2.JPG";
import image3 from "../src/img/photography/image3.JPG";
import image4 from "../src/img/photography/image4.JPG";
import image5 from "../src/img/photography/image5.JPG";
import image6 from "../src/img/photography/image6.jpg";
import image7 from "../src/img/photography/image7.JPG";
import image8 from "../src/img/photography/image8.JPG";
import image9 from "../src/img/photography/image9.JPG";
import image10 from "../src/img/photography/image10.JPG";
import image11 from "../src/img/photography/image11.JPG";
import image12 from "../src/img/photography/image12.jpg";
import image13 from "../src/img/photography/image13.JPG";
import image14 from "../src/img/photography/image14.jpg";
import image15 from "../src/img/photography/image15.JPG";
import image16 from "../src/img/photography/image16.JPG";
import image17 from "../src/img/photography/image17.jpeg";
import image18 from "../src/img/photography/image18.JPG";
import image19 from "../src/img/photography/image19.jpeg";
import image20 from "../src/img/photography/image20.jpg";
import image21 from "../src/img/photography/image21.JPG";
import image22 from "../src/img/photography/image22.JPG";
import image23 from "../src/img/photography/image23.JPG";
import image24 from "../src/img/photography/image24.jpg";
import image25 from "../src/img/photography/image25.JPG";
import image26 from "../src/img/photography/image26.jpg";
import image27 from "../src/img/photography/image27.JPG";
import image28 from "../src/img/photography/image28.JPG";
import image29 from "../src/img/photography/image29.JPG";
import image30 from "../src/img/photography/image30.JPG";
import image31 from "../src/img/photography/image31.JPG";

//GD images

import compImage1 from "../src/img/companies/ArtsCouncil_Black.png";
import compImage2 from "../src/img/companies/BodyAndSoul_Black.png";
import compImage3 from "../src/img/companies/CancerResearch_Black.png";
import compImage4 from "../src/img/companies/Chanel_Black.png";
import compImage5 from "../src/img/companies/Cocacola_Black.png";
import compImage6 from "../src/img/companies/ComicRelief_Black.png";
import compImage7 from "../src/img/companies/CompareTheMarket_Black.png";
import compImage8 from "../src/img/companies/Crisis_Black.png";
import compImage9 from "../src/img/companies/Google_Black.png";
import compImage10 from "../src/img/companies/IslamicRelief_Black.png";
import compImage11 from "../src/img/companies/J_Hope_Logo_Preview.jpg";
import compImage12 from "../src/img/companies/LinkUpTv_Black.png";
import compImage13 from "../src/img/companies/LloydsBankFoundation_Black.png";
import compImage14 from "../src/img/companies/MusicForYouth_Black.png";
import compImage15 from "../src/img/companies/MuslimAid_Black.png";
import compImage16 from "../src/img/companies/NaturalHistoryMuseum_Black.png";
import compImage17 from "../src/img/companies/Nike_Black.png";
import compImage18 from "../src/img/companies/o2_Black.png";
import compImage19 from "../src/img/companies/Oxfam_Black.png";
import compImage20 from "../src/img/companies/RoyalTrinityHospice_Black.png";
import compImage21 from "../src/img/companies/SaveTheChildren_Black.png";
import compImage22 from "../src/img/companies/SBTV_Black.png";
import compImage23 from "../src/img/companies/ScottishWidows_Black.png";
import compImage24 from "../src/img/companies/StandardCharted_Black.png";
import compImage25 from "../src/img/companies/UnicornTheatre_Black.png";
import compImage26 from "../src/img/companies/Unilever_Black.png";
import compImage27 from "../src/img/companies/UtopiaTheatre_Black.png";
import compImage28 from "../src/img/companies/VCCP_Black.png";
import compImage29 from "../src/img/companies/WiedenAndKennedy_Black.png";
import compImage30 from "../src/img/companies/ZakatFoundation_Black.png";

// comapny logos

import gdImage1 from "../src/img/graphics/Acoustic_Live.jpg";
import gdImage2 from "../src/img/graphics/AshestoArrows.jpg";
import gdImage3 from "../src/img/graphics/Black_Lives_Matter_Poster_Preview.jpg";
import gdImage4 from "../src/img/graphics/ChannelU_ThrowbackParty_Preview.jpg";
import gdImage5 from "../src/img/graphics/Christmas Jumper Day Preview.jpg";
import gdImage6 from "../src/img/graphics/Crisis_Everybody_In_Preview.jpg";
import gdImage7 from "../src/img/graphics/Darul_Kitab_Logo_Preview.jpg";
import gdImage8 from "../src/img/graphics/Last_Black_Man_Standing_Poster_Preview.jpg";
import gdImage9 from "../src/img/graphics/Lethal_B_Flex_Preview.jpg";
import gdImage10 from "../src/img/graphics/Lloyds_Bank_Foundation_Preview.jpg";
import gdImage11 from "../src/img/graphics/Malunga_Music_Event_Poster_Preview.jpg";
import gdImage12 from "../src/img/graphics/o2_Touch_Rugby_Preview.jpg";
import gdImage13 from "../src/img/graphics/OneWay_Film_Poster_Preview.jpg";
import gdImage14 from "../src/img/graphics/Presha_J_Live_For_You_Preview.jpg";
import gdImage15 from "../src/img/graphics/RaceForLife48Sheet_Preview.jpg";
import gdImage16 from "../src/img/graphics/The_Link_Up_Mag_Preview.jpg";
import gdImage17 from "../src/img/graphics/TrailsofBablyon_Preview.jpg";
import gdImage18 from "../src/img/graphics/Tuffon_Hall_Logo_Preview.jpg";
import gdImage19 from "../src/img/graphics/Unilever_Poster_Preview.jpg";
import gdImage20 from "../src/img/graphics/Vive_Exhibition_Poster_Preview.jpg";
import gdImage21 from "../src/img/graphics/Vive_Magazine_Cover_Preview.jpg";

// creative direction

import IrBeGood from "../src/video/IslamicRelief/be-a-good-boy-islamic-relief-uk.mp4";
import IrDryDays from "../src/video/IslamicRelief/drydays-islamic-relief-uk.mp4";
import IrGrowUp from "../src/video/IslamicRelief/if-i-grow-up-islamic-relief-uk.mp4";
import IrBoat from "../src/video/IslamicRelief/let-me-off-the-boat-islamic-relief-uk.mp4";
import IrBeGoodThumb from "../src/video/IslamicRelief/Be_A_Good_Boy_PREVIEW.gif";
import IrDryDaysThumb from "../src/video/IslamicRelief/Dry_Days_PREVIEW.gif";
import IrGrowUpThumb from "../src/video/IslamicRelief/If_I_Grew_Up.gif";
import IrBoatThumb from "../src/video/IslamicRelief/Let_Me_Off_The_Boat.gif";

import NeedSomeMore from "../src/video/NeedSomeMoreGirls/WeNeedSomeMoreGirlsInHere_.mp4";
import NeedSomeMoreThumb from "../src/video/NeedSomeMoreGirls/WNSMGIH.gif";

import CallLater from "../src/video/OlaZainab/ola-zainab-call-me-later-official-video1.mp4";
import CallLaterThumb from "../src/video/OlaZainab/Ola_Call_Me_Later_Preview.gif";

import SsShoesHate from "../src/video/SpotlightSeries/spotlight-series-from-life-in-my-shoes-hate.mp4";
import SsShoesDontSee from "../src/video/SpotlightSeries/spotlight-series-from-life-in-my-shoes-the-me-you-don-t-see.mp4";
import SsShoesTorn from "../src/video/SpotlightSeries/spotlight-series-from-life-in-my-shoes-torn-apart.mp4";
import SsShoesMyths from "../src/video/SpotlightSeries/spotlights-series-from-life-in-my-shoes-myths.mp4";
import SsShoesTrailer from "../src/video/SpotlightSeries/the-spotlight-series-from-life-in-my-shoes-trailer.mp4";
import SsShoesHateThumb from "../src/video/SpotlightSeries/Spotlight_Hate_Preview.gif";
import SsShoesDontSeeThumb from "../src/video/SpotlightSeries/The_Me_You_Dont_See.gif";
import SsShoesTornThumb from "../src/video/SpotlightSeries/Spotlight_TornApart_Preview.gif";
import SsShoesMythsThumb from "../src/video/SpotlightSeries/Spotlights_Myths.gif";
import SsShoesTrailerThumb from "../src/video/SpotlightSeries/Spotlight_Trailer.gif";

// import icons
import { GrFacebookOption } from "react-icons/gr";
import { IoMdArrowForward } from "react-icons/io";
import { FiSend } from "react-icons/fi";
import { FaPlay, FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { IoLogoInstagram, IoLogoLinkedin, IoLogoTwitter } from "react-icons/io";

export const headerData = {
  logo: RadziLogo,
};

export const navData = {
  items: [
    { href: "#about", name: "About" },
    { href: "#photography", name: "Photography" },
    { href: "#graphic-design", name: "Graphic Design" },
    { href: "#creative-direction", name: "Creative Direction" },
  ],
};

export const socialData = [
  { href: "https://facebook.com/munya.chidakwa", icon: <GrFacebookOption /> },
  { href: "https://instagram.com/_radzii", icon: <IoLogoInstagram /> },
  { href: "/", icon: <IoLogoLinkedin /> },
];

export const heroData = {
  title: "Radzi Creative",
  subtitle: "",
  btnText: "get in touch",
  btnIcon: <IoMdArrowForward />,
};

export const aboutData = {
  title: "Who am I:",
  subtitle1:
    "I am a Zimbabwean born, London based creative with a strong foundation in design, photography and creative direction. With over eleven years of experience, I specialise in integrated creative campaigns, creating strategy-driven solutions for renowned brands, leading agencies and start-ups.",
  subtitle2:
    "Whatever the project, my goal is always the same. Create something direct, engaging, simple, witty, purposeful and memorable.",
  btnText: "Know more",
  btnIcon: <IoMdArrowForward />,
  abtImg: AboutImg,
};

export const graphicData = {
  title: "Graphic Design",
  btnText: "Get In Touch",
  btnIcon: <IoMdArrowForward />,
  images: [
    {
      src: gdImage1,
      original: gdImage1,
      width: 1080,
      height: 1080,
    },
    {
      src: gdImage2,
      original: gdImage2,
      width: 1080,
      height: 1080,
    },
    {
      src: gdImage3,
      original: gdImage3,
      width: 1080,
      height: 1080,
    },
    {
      src: gdImage4,
      original: gdImage4,
      width: 1080,
      height: 1080,
    },
    {
      src: gdImage5,
      original: gdImage5,
      width: 1080,
      height: 1080,
    },
    {
      src: gdImage6,
      original: gdImage6,
      width: 1080,
      height: 1080,
    },
    {
      src: gdImage7,
      original: gdImage7,
      width: 1080,
      height: 1080,
    },
    {
      src: gdImage8,
      original: gdImage8,
      width: 1080,
      height: 1080,
    },
    {
      src: gdImage9,
      original: gdImage9,
      width: 1080,
      height: 1080,
    },
    {
      src: gdImage10,
      original: gdImage10,
      width: 1080,
      height: 1080,
    },
    {
      src: gdImage11,
      original: gdImage11,
      width: 1080,
      height: 1080,
    },
    {
      src: gdImage12,
      original: gdImage12,
      width: 1080,
      height: 1080,
    },
    {
      src: gdImage13,
      original: gdImage13,
      width: 1080,
      height: 1080,
    },
    {
      src: gdImage14,
      original: gdImage14,
      width: 1080,
      height: 1080,
    },
    {
      src: gdImage15,
      original: gdImage15,
      width: 1080,
      height: 1080,
    },
    {
      src: gdImage16,
      original: gdImage16,
      width: 1080,
      height: 1080,
    },
    {
      src: gdImage17,
      original: gdImage17,
      width: 1080,
      height: 1080,
    },
    {
      src: gdImage18,
      original: gdImage18,
      width: 1080,
      height: 1080,
    },
    {
      src: gdImage19,
      original: gdImage19,
      width: 1080,
      height: 1080,
    },
    {
      src: gdImage20,
      original: gdImage20,
      width: 1080,
      height: 1080,
    },
    {
      src: gdImage21,
      original: gdImage21,
      width: 1080,
      height: 1080,
    },
  ],
};

export const companiesData = {
  title: "Who I've Worked With",
  btnText: "Get In Touch",
  btnIcon: <IoMdArrowForward />,
  images: [
    {
      imgSrc: compImage1,
      imgAlt: "compImage1",
    },
    {
      imgSrc: compImage2,
      imgAlt: "compImage2",
    },
    {
      imgSrc: compImage3,
      imgAlt: "compImage3",
    },
    {
      imgSrc: compImage4,
      imgAlt: "compImage4",
    },
    {
      imgSrc: compImage5,
      imgAlt: "compImage5",
    },
    {
      imgSrc: compImage6,
      imgAlt: "compImage6",
    },
    {
      imgSrc: compImage7,
      imgAlt: "compImage7",
    },
    {
      imgSrc: compImage8,
      imgAlt: "compImage8",
    },
    {
      imgSrc: compImage9,
      imgAlt: "compImage9",
    },
    {
      imgSrc: compImage10,
      imgAlt: "compImage10",
    },
    {
      imgSrc: compImage11,
      imgAlt: "compImage11",
    },
    {
      imgSrc: compImage12,
      imgAlt: "compImage12",
    },
    {
      imgSrc: compImage13,
      imgAlt: "compImage1",
    },
    {
      imgSrc: compImage14,
      imgAlt: "compImage2",
    },
    {
      imgSrc: compImage15,
      imgAlt: "compImage3",
    },
    {
      imgSrc: compImage16,
      imgAlt: "compImage4",
    },
    {
      imgSrc: compImage17,
      imgAlt: "compImage5",
    },
    {
      imgSrc: compImage18,
      imgAlt: "compImage6",
    },
    {
      imgSrc: compImage19,
      imgAlt: "compImage7",
    },
    {
      imgSrc: compImage20,
      imgAlt: "compImage8",
    },
    {
      imgSrc: compImage21,
      imgAlt: "compImage9",
    },
    {
      imgSrc: compImage22,
      imgAlt: "compImage10",
    },
    {
      imgSrc: compImage23,
      imgAlt: "compImage11",
    },
    {
      imgSrc: compImage24,
      imgAlt: "compImage12",
    },
    {
      imgSrc: compImage25,
      imgAlt: "compImage7",
    },
    {
      imgSrc: compImage26,
      imgAlt: "compImage8",
    },
    {
      imgSrc: compImage27,
      imgAlt: "compImage9",
    },
    {
      imgSrc: compImage28,
      imgAlt: "compImage10",
    },
    {
      imgSrc: compImage29,
      imgAlt: "compImage11",
    },
  ],
};

export const galleryData = {
  title: "Photography",
  btnText: "Get In Touch",
  btnIcon: <IoMdArrowForward />,
  images: [
    {
      src: image1,
      original: image1,
      width: 5184,
      height: 3456,
    },
    {
      src: image2,
      original: image2,
      width: 3456,
      height: 5184,
    },
    {
      src: image3,
      original: image3,
      width: 5184,
      height: 3456,
    },
    {
      src: image4,
      original: image4,
      width: 3456,
      height: 5184,
    },
    {
      src: image5,
      original: image5,
      width: 5184,
      height: 3456,
    },
    {
      src: image6,
      original: image6,
      width: 3456,
      height: 5184,
    },
    {
      src: image7,
      original: image7,
      width: 5184,
      height: 3456,
    },
    {
      src: image8,
      original: image8,
      width: 3456,
      height: 5184,
    },
    {
      src: image9,
      original: image9,
      width: 5184,
      height: 3456,
    },
    {
      src: image10,
      original: image10,
      width: 3456,
      height: 5184,
    },
    {
      src: image11,
      original: image11,
      width: 5184,
      height: 3456,
    },
    {
      src: image12,
      original: image12,
      width: 3456,
      height: 5184,
    },
    {
      src: image13,
      original: image13,
      width: 5184,
      height: 3456,
    },
    {
      src: image14,
      original: image14,
      width: 3456,
      height: 5184,
    },
    {
      src: image15,
      original: image15,
      width: 5184,
      height: 3456,
    },
    {
      src: image16,
      original: image16,
      width: 3456,
      height: 5184,
    },
    {
      src: image17,
      original: image17,
      width: 5184,
      height: 3456,
    },
    {
      src: image18,
      original: image18,
      width: 3456,
      height: 5184,
    },
    {
      src: image19,
      original: image19,
      width: 5184,
      height: 3456,
    },
    {
      src: image20,
      original: image20,
      width: 3456,
      height: 5184,
    },
    {
      src: image21,
      original: image21,
      width: 5184,
      height: 3456,
    },
    {
      src: image22,
      original: image22,
      width: 3456,
      height: 5184,
    },
    {
      src: image23,
      original: image23,
      width: 5184,
      height: 3456,
    },
    {
      src: image24,
      original: image24,
      width: 3456,
      height: 5184,
    },
    {
      src: image25,
      original: image25,
      width: 5184,
      height: 3456,
    },
    {
      src: image26,
      original: image26,
      width: 3456,
      height: 5184,
    },
    {
      src: image27,
      original: image27,
      width: 5184,
      height: 3456,
    },
    {
      src: image28,
      original: image28,
      width: 3456,
      height: 5184,
    },
    {
      src: image29,
      original: image29,
      width: 5184,
      height: 3456,
    },
    {
      src: image30,
      original: image30,
      width: 3456,
      height: 5184,
    },
    {
      src: image31,
      original: image31,
      width: 5184,
      height: 3456,
    },
  ],
};

export const creativeData = {
  title: "Creative Direction",
  btnText: "Get In Touch",
  btnIcon: <IoMdArrowForward />,
  irVideos: [
    {
      original: IrBeGood,
      type: "video/mp4",
      thumbnail: IrBeGoodThumb,
    },
    {
      original: IrBoat,
      type: "video/mp4",
      thumbnail: IrBoatThumb,
    },
    {
      original: IrDryDays,
      type: "video/mp4",
      thumbnail: IrDryDaysThumb,
    },
    {
      original: IrGrowUp,
      type: "video/mp4",
      thumbnail: IrGrowUpThumb,
    },
  ],
  needMoreVideo: [
    {
      original: NeedSomeMore,
      type: "video/mp4",
      thumbnail: NeedSomeMoreThumb,
    },
  ],
  olaVideo: [
    {
      original: CallLater,
      type: "video/mp4",
      thumbnail: CallLaterThumb,
    },
  ],
  spotlightVideo: [
    {
      original: SsShoesHate,
      type: "video/mp4",
      thumbnail: SsShoesHateThumb,
    },
    {
      original: SsShoesDontSee,
      type: "video/mp4",
      thumbnail: SsShoesDontSeeThumb,
    },
    {
      original: SsShoesMyths,
      type: "video/mp4",
      thumbnail: SsShoesMythsThumb,
    },
    {
      original: SsShoesTorn,
      type: "video/mp4",
      thumbnail: SsShoesTornThumb,
    },
    {
      original: SsShoesTrailer,
      type: "video/mp4",
      thumbnail: SsShoesTrailerThumb,
    },
  ],
};

export const interviewData = {
  title:
    "“Consider what you desire. Your tattoo artist will never tell you what tattoo to have.”",
  btnText: "Watch it now",
  btnIcon: <FaPlay />,
};

export const testimonialData = [
  {
    quoteImg: QuoteImg,
    message:
      "The entire team is extremely kind and friendly. They're fantastic. They're great at what they do! And it's unique.They will properly consult with you.",
    name: "Jack Geoffrey",
    occupation: "Tattoo Artist",
  },
  {
    quoteImg: QuoteImg,
    message:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam culpa asperiores officia natus dignissimos autem possimus molestias aperiam?",
    name: "Douglas Hane",
    occupation: "Tattoo Artist",
  },
];

export const contactData = {
  title: "Get in touch with me:",
  info: [
    {
      title: "LA office",
      subtitle:
        "In velit arcu posuere integer sit amet, consectetur nun adipiscing elit. Duis porttitor massa tellus",
      address: {
        icon: <FaMapMarkerAlt />,
        name: "784 Norman Street, Los Angeles",
      },
      phone: {
        icon: <FaPhoneAlt />,
        number: "+49 93 30493943",
      },
      email: {
        icon: <FaEnvelope />,
        address: "radzicreative@gmail.com",
      },
      link: "Get location",
    },
    {
      title: "NYC office",
      subtitle:
        "Sit amet, consectetur nun in velit arcu posuere integer. Adipiscing elit duis porttitor massa tellus",
      address: {
        icon: <FaMapMarkerAlt />,
        name: "1630 Elm Drive, New York City",
      },
      phone: {
        icon: <FaPhoneAlt />,
        number: "+49 34 36573355",
      },
      email: {
        icon: <FaEnvelope />,
        address: "contact@yourcompany.com",
      },
      link: "Get location",
    },
  ],
  form: {
    name: "Write your name here",
    email: "Write your email address",
    message: "Write your messages here",
    btnText: "Send it",
  },
};

export const footerData = {
  about: {
    title: "Contact Me",
    subtitle: "Get In Touch Via Email for any enquiries",
    address: {
      icon: <FaMapMarkerAlt />,
      name: "1630 Elm Drive, New York City",
    },
    phone: {
      icon: <FaPhoneAlt />,
      number: "+49 34 36573355",
    },
    email: {
      icon: <FaEnvelope />,
      address: "radzicreative@gmail.com",
    },
  },
  links: {
    title: "Useful links",
    items: [
      { href: "/#about", name: "About" },
      { href: "/#photography", name: "Photography" },
      { href: "/#graphic-design", name: "Graphic Design" },
      { href: "/#creative-direction", name: "Creative Direction" },
    ],
  },
  program: {
    title: "Working Time",
    items: [
      { name: "Mon - Tue / Appointment" },
      { name: "Wed - Fri / 10:00 - 9:00pm" },
      { name: "Sat / 10:00 - 6:00pm" },
      { name: "Sun / no work on this day" },
    ],
  },
  newsletter: {
    title: "Social Links",
    subtitle: "Find Me On Social Media",
    form: {
      placeholder: "Your email address",
      icon: <FiSend />,
    },
  },
};
