import React, { useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import { creativeData } from "../data";

import { motion } from "framer-motion";
import { fadeIn } from "../variants";

import "swiper/scss";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

//import "./styles.css";

import { FreeMode, Navigation, Thumbs } from "swiper";

const CreativeNsm = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { title, btnText, btnIcon, videos } = creativeData;

  return (
    <div>
      <Swiper
        style={{
          "--swiper-navigation-color": "#000",
        }}
        spaceBetween={10}
        navigation={true}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >
        {creativeData.needMoreVideo.map((slide, index) => {
          const { original, type, thumbnail } = slide;
          return (
            <SwiperSlide key={index}>
              <div className="">
                <div className="mb-5 flex justify-center items-center">
                  <video width="800" height="240" controls>
                    <source src={original} type={type} />
                  </video>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
export default CreativeNsm;
