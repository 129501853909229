import React from "react";
import { heroData } from "../data";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const container = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.6,
    },
  },
};

const Hero = () => {
  const { title, subtitle, btnText, btnIcon } = heroData;

  return (
    <section className="bg-hero bg-cover bg-center min-h-[40vh] lg:h-[648px] xl:h-screen bg-no-repeat relative mt-[120px] lg:mt-[50px]">
      <motion.div
        variants={container}
        initial="hidden"
        whileInView={"show"}
        className="container mx-auto min-h-[40vh] lg:h-full flex items-center justify-center lg:justify-start"
      >
        <div className="text-white text-center lg:text-left lg:max-w-[640px]">
          <motion.h1 variants={fadeIn("down")} className="h1 text-black">
            {title}
          </motion.h1>
          <motion.p
            variants={fadeIn("down")}
            className="mb-8 lg:mb-16 max-w-lg leading-relaxed text-black"
          >
            {subtitle}
          </motion.p>
        </div>
      </motion.div>
    </section>
  );
};

export default Hero;
