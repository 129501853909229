import React, { useState, useEffect } from "react";

import { headerData, navData } from "../data";

import Nav from "./Nav";
//import NavMobile from "./NavMobile";
import Socials from "./Socials";

//import { TiThMenuOutline } from "react-icons/ti";
import { IconContext } from "react-icons";

const Header = () => {
  const { logo } = headerData;
  const {items} = navData

  const [isActive, setIsActive] = useState(false);
  //const [mobileNav, setMobileNav] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 10 ? setIsActive(true) : setIsActive(false);
    });
  });

  return (
    <header
      className={`${
        isActive ? "h-[80px] lg:h-[100px] shadow-lg" : "h-[90px] lg:h-[110px]"
      } fixed bg-white left-0 right-0 z-10 max-w-[1920px] w-full mx-auto transition-all duration-300`}
    >
      <div className="flex justify-center lg:justify-between items-center h-full pl-[50px] pr-[60px] ">
        <a className="py-3" href="/">
          <img className="w-[146px] h-[70px] lg:w-[188px] lg:h-[90px]" src={logo} alt="" />
        </a>
        <div className="hidden lg:flex">
          <Nav />
        </div>
        
        
        <div className="hidden lg:flex">
          <IconContext.Provider value={{ size: 25 }}>
            <Socials />
          </IconContext.Provider>
        </div>
      </div>
      <div className="flex h-[50px] lg:hidden justify-center bg-white p-4">
          <ul className='flex justify-center gap-x-[30px]'>
            {items.map((item, index) => {
              return (
                <li key={index}>
                  <a className='font-primary text-sm hover:border-b-2 hover:border-dark transition duration-300' href={item.href}>{item.name}</a>
                </li>
              )
            })}
          </ul>
        </div>
    </header>
  );
};

export default Header;
