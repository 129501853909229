import React, { useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import { creativeData } from "../data";

import CreativeOla from "../components/CreativeOla";
import CreativeSpotlight from "../components/CreativeSpotlight";
import CreativeIr from "../components/CreativeIr";
import CreativeNsm from "../components/CreativeNsm";

import { motion } from "framer-motion";
import { fadeIn } from "../variants";

import "swiper/scss";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

//import "./styles.css";

import { FreeMode, Navigation, Thumbs } from "swiper";

const CreativeDirection = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [openTab, setOpenTab] = useState(1);

  const { title, btnText, btnIcon, videos } = creativeData;

  return (
    <section
      id="creative-direction"
      className="section relative mt-[40px] lg:mt-0"
    >
      <div className="container mx-auto">
        <motion.h2
          variants={fadeIn("up")}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.6 }}
          className="h2 max-w-[370px] lg:mb-20"
        >
          {title}
        </motion.h2>
      </div>
      <motion.div
        variants={fadeIn("up")}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.2 }}
        className="max-w-[1024px] px-10 mx-auto mb-8 lg:mb-20"
      >
        <>
          <div className="flex flex-wrap">
            <div className="w-full">
              <ul
                className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
                role="tablist"
              >
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                  <a
                    className={
                      "text-sm lg:text-base font-outfit font-semibold uppercase px-5 py-3 block leading-normal " +
                      (openTab === 1
                        ? "text-white bg-dark"
                        : "text-dark bg-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(1);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    Islamic Relief
                  </a>
                </li>
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                  <a
                    className={
                      "text-sm lg:text-base font-outfit  font-semibold uppercase px-5 py-3 block leading-normal " +
                      (openTab === 2
                        ? "text-white bg-dark"
                        : "text-dark bg-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(2);
                    }}
                    data-toggle="tab"
                    href="#link2"
                    role="tablist"
                  >
                    Ola Zainab
                  </a>
                </li>
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                  <a
                    className={
                      "text-sm lg:text-base font-outfit  font-semibold uppercase px-5 py-3 block leading-normal " +
                      (openTab === 3
                        ? "text-white bg-dark"
                        : "text-dark bg-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(3);
                    }}
                    data-toggle="tab"
                    href="#link3"
                    role="tablist"
                  >
                    Need Some More
                  </a>
                </li>
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                  <a
                    className={
                      "text-sm lg:text-base font-outfit  font-semibold uppercase px-5 py-3 block leading-normal " +
                      (openTab === 4
                        ? "text-white bg-dark"
                        : "text-dark bg-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(4);
                    }}
                    data-toggle="tab"
                    href="#link4"
                    role="tablist"
                  >
                    Spotlight Series
                  </a>
                </li>
              </ul>
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="px-4 py-5 flex-auto">
                  <div className="tab-content tab-space">
                    <div
                      className={openTab === 1 ? "block" : "hidden"}
                      id="link1"
                    >
                      <CreativeIr />
                    </div>
                    <div
                      className={openTab === 2 ? "block" : "hidden"}
                      id="link2"
                    >
                      <CreativeOla />
                    </div>
                    <div
                      className={openTab === 3 ? "block" : "hidden"}
                      id="link3"
                    >
                      <CreativeNsm />
                    </div>
                    <div
                      className={openTab === 4 ? "block" : "hidden"}
                      id="link4"
                    >
                      <CreativeSpotlight />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </motion.div>
    </section>
  );
};
export default CreativeDirection;
